import "./Salary.css";

export default function Salary({ occupation, salary }) {
  const formatCurrency = (number) => {
    if (!number) return null;
    const s = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return s + " kr";
  };

  return (
    <div>
      <h2>Gjennomsnittlig brutto månedslønn</h2>
      <div className="salarytable">
        <p>Privat sektor og offentlige eide foretak</p>
        <p>{formatCurrency(salary.privateSector)}</p>

        <p>Kommuneforvaltningen</p>
        <p>{formatCurrency(salary.localGovernment)}</p>

        <p>Statsforvaltningen</p>
        <p>{formatCurrency(salary.centralGovernment)}</p>

        <p>Alle sektorer</p>
        <p>{formatCurrency(salary.all)}</p>
      </div>
    </div>
  );
}
