import axios from "axios";
import { useCallback } from "react";

const baseURL = "/api";

// Noee about using useCallback: This is necessary so that the function can be used as dependency
// in useEffect() in the components. Or else, the component will reload in an infinite loop.

export const useApi = () => {
  const callApi = useCallback(
    async ({ url, method, data }) =>
      axios
        .request({
          baseURL,
          url,
          ...(method ? { method } : {}),
          ...(data ? { data } : {}),
        })
        .then((result) => result.data)
        .catch((error) => {
          console.log("HER");
          return null;
        }),
    []
  );

  return { callApi };
};
