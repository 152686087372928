import { useEffect, useState } from "react";
import { useApi } from "./hooks/useApi";

import { Autocomplete, TextField } from "@mui/material";
import "./App.css";
import Salary from "./Salary";

const MODE_READY = 0;
const MODE_FETCHING = 1;
const MODE_ERROR = 2;

function App() {
  const { callApi } = useApi();
  const [occupationList, setOccupationList] = useState(null);
  const [salary, setSalary] = useState(null);
  const [mode, setMode] = useState(MODE_READY);

  useEffect(() => {
    callApi({ url: "statistics/occupations" })
      .then((occupations) => setOccupationList(occupations))
      .catch((error) => console.log(error));
  }, [callApi]);

  const handleSelection = (event, value) => {
    setSalary(null);
    if (!value) {
      setMode(MODE_READY);
      return;
    }
    setMode(MODE_FETCHING);
    callApi({ url: "statistics/salaries/" + value.id })
      .then((salary) => {
        setSalary(salary);
        setMode(salary ? MODE_READY : MODE_ERROR);
      })
      .catch((error) => {
        setMode();
      });
  };

  return (
    <div className="App">
      <p className="ingress">Her kan du se hva ansatte med samme yrke som deg tjener i gjennomsnitt.</p>

      <p>Kalkulatoren viser gjennomsnittlig brutto månedslønn for heltidsansatte i ulike sektorer. Tallene er basert på statistikk fra SSB for 2023. Kalkulatoren skiller ikke på kjønn.</p>

      {occupationList && (
        <Autocomplete onChange={handleSelection} disablePortal id="occupation" options={occupationList} sx={{ width: 300 }} renderInput={(params) => <TextField {...params} label="Velg yrke" />} />
      )}

      <p>
        Velg ditt yrke ved å taste inn et nøkkelord eller trykke pilen i feltet over. Hvis du for eksempel taster inn "Salg" eller "Selger", vil alle yrker som inneholder søkeordet vises i listen, og
        du kan velge din kategori.
      </p>

      <p>
        Du kan kreve av arbeidsgiver å få vite hvilken stillingskode du rapporteres inn på etter{" "}
        <a href="https://lovdata.no/lov/2012-06-22-43/§9" target="_blank" rel="noreferrer">
          A-opplysningsloven § 9
        </a>
        .
      </p>

      <p>
        Dersom du er usikker på hva en yrkestittel inneholder, vil du finne en beskrivelse i{" "}
        <a href="https://www.ssb.no/a/publikasjoner/pdf/notat_201117/notat_201117.pdf" target="_blank" rel="noreferrer">
          Standard for Yrkesklassifisering (STYRK 08) her
        </a>
        . Hold nede tastene ctrl og f for å søke i dokumentet.
      </p>

      {!occupationList && <div>Henter yrker...</div>}
      {mode === MODE_READY && salary && <Salary salary={salary} />}
      {mode === MODE_FETCHING && <h2>Henter lønn...</h2>}
      {mode === MODE_ERROR && <h2>Det oppstod en feil ved henting av lønn. Prøv igjen senere.</h2>}
    </div>
  );
}

export default App;
